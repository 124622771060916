import React from "react"
import {Button} from "react-bootstrap";
import lang from '../Localization';
import '../Styles/Faq.css';

class SuccessPage extends React.Component {
	
    reset = () => {
        this.props.appFunctions.reset();
    }

    render() {
        return (
            <div style={{textAlign: "center"}}>
                <h3>{lang.success.thanks}</h3>
                <Button onClick={this.reset} id='button-send-new'>{lang.success.link}</Button>
                <h3>{lang.faq.title}</h3>
                <div id="faq-div">
                    <div className="faq-div-question">{lang.faq.what.question}</div>
                    <p class="faq-answers">{lang.faq.what.answer}<a href={lang.faq.what.link} target="_blank" rel="noreferrer">{lang.faq.what.link_text}</a>.</p>
                    <div className="faq-div-question">{lang.faq.time.question}</div>
                    <p class="faq-answers">{lang.faq.time.answer}</p>
                    <div className="faq-div-question">{lang.faq.where.question}</div>
                    <p class="faq-answers">{lang.faq.where.answer}<a href={lang.faq.where.link} target="_blank" rel="noreferrer">{lang.faq.where.link_text}</a>{lang.faq.where.answer2}.</p>
                    <div className="faq-div-question">{lang.faq.mistake.question}</div>
                    <p class="faq-answers">{lang.faq.mistake.answer}</p>
                </div>
            </div>
        );
    }
  }

  export { SuccessPage }
