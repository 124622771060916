import React from "react"
import { Button } from "react-bootstrap";
import lang from '../Localization';

import logo from '../images/KK_logo_keskitetty_sf_swe_rgb_white.png';
import '../Styles/Navbar.css';

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showContactInfo: false
        }
    }
    next = () => {
        this.props.appFunctions.nextPage();
    }

    prev = () => {
        this.props.appFunctions.previousPage();
    }

    setLanguage = (lang) => {
        this.props.appFunctions.setLanguage(lang);
    }

    toggleContactInfo = () => {
        this.setState((state) => {
            return { showContactInfo: !state.showContactInfo }
        });
    }

    render() {
        let contactInfo = "";
        if(this.state.showContactInfo) {
            contactInfo = <div className="contact-info-display">
                <div className="contact-info-display-paragraph">
                    <a target="_blank" rel="noreferrer" href={lang.legalDepositWebsite}>{lang.legalDepositOffice}</a>
                </div>
                <div>
                    <a href={lang.legalDepositContactFormURL} onClick={this.props.appFunctions.openFeedbackForm}>{lang.legalDepositContactForm}</a>
                </div>
            </div>
        }

        return (
            <header className="navbar">
                <img src={logo} alt="" className="navbar-logo" onClick={ () => {this.props.appFunctions.selectPage("files");} }/>

                <div className="page-tabs">
                    <button onClick={ () => {this.props.appFunctions.selectPage("files");} } className={this.props.page === "files" ? "page-tab-selected" : "page-tab" }>
                        <span className="page-tab-number">1.</span>
                        <span className="d-none d-md-inline">{lang.publicationsToSubmit}</span>
                    </button>
                    <button className={this.props.page === "contact" ? "page-tab-selected" : "page-tab"}>
                        <span className="page-tab-number">2.</span>
                        <span className="d-none d-md-inline">{lang.submitterInfo}</span>
                    </button>
                    <button className={this.props.page === "confirm" ? "page-tab-selected" : "page-tab"}>
                        <span className="page-tab-number">3.</span>
                        <span className="d-none d-md-inline">{lang.formSending}</span>
                    </button>
                </div>

                <div className="language-change-buttons">
										
                    <button className={lang.getLanguage() === "fi" ? "language-change-button-selected" : "language-change-button"} onClick={ () => {this.setLanguage("fi")} }>fi</button>
                    <button className={lang.getLanguage() === "sv" ? "language-change-button-selected" : "language-change-button"} onClick={ () => {this.setLanguage("sv")} }>sv</button>
                    <button className={lang.getLanguage() === "en" ? "language-change-button-selected" : "language-change-button"} onClick={ () => {this.setLanguage("en")} }>en</button>
                </div>

                <div className="contact-info-button-wrapper">
                    <Button className="nav-faq-button" onClick={ () => {this.props.appFunctions.openFaqForm();} }>{lang.faq.navbar_title}</Button>
                    <Button onClick={this.toggleContactInfo} className="contact-info-button">{lang.contact}</Button>
                </div>
                {contactInfo}
            </header>
        );
    }
  }

  export { Navbar }
