import React from "react"
import {Modal, Button } from "react-bootstrap";

import lang from '../Localization';

class FaqDisplay extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        };
    }
    
    closeForm = () => {
        this.props.appFunctions.closeFaqForm();
    }

    render() {
        return (
            <Modal size="lg" centered show={this.props.show}>
                <Modal.Body>
                <div style={{textAlign: "center"}}>
                    <h3>{lang.faq.title}</h3>
                    <div id="faq-div-modal">
                        <div className="faq-div-question">{lang.faq.what.question}</div>
                        <p class="faq-answers">{lang.faq.what.answer}<a href={lang.faq.what.link} target="_blank" rel="noreferrer">{lang.faq.what.link_text}</a>.</p>
                        <div className="faq-div-question">{lang.faq.time.question}</div>
                        <p class="faq-answers">{lang.faq.time.answer}</p>
                        <div className="faq-div-question">{lang.faq.where.question}</div>
                        <p class="faq-answers">{lang.faq.where.answer}<a href={lang.faq.where.link} target="_blank" rel="noreferrer">{lang.faq.where.link_text}</a>{lang.faq.where.answer2}.</p>
                        <div className="faq-div-question">{lang.faq.mistake.question}</div>
                        <p class="faq-answers">{lang.faq.mistake.answer}</p>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeForm}>{lang.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
  }

  export { FaqDisplay }